<template>
  <div>
    <div class="container">
      <div class="title">
        <div id="datePicker">
          <label class="title_label" for="">单据日期:</label>
          <content>
            <div>
              <VueDatePicker class="date-picker" v-model="date" placeholder="年/月/日" utc></VueDatePicker>
            </div>
          </content>
        </div>
        <div class="code-t">
          <label class="title_label" for="">订单编号:</label>
          <content>2474828937482</content>
        </div>
      </div>
      <table class="my_table" align="center">
        <tr class="thead_container">
          <td>成品名称</td>
          <td>单位规格</td>
          <td>数量</td>
          <td>操作</td>
        </tr>
        <tr height="40px" v-for="(item, index) in tableList" :key="index">
          <td class="dp_td">
            <input class="name_ipt" v-model="item.name" placeholder="请输入" type="text" name="" id="" disabled>
            <div class="dropdown" @click="editBtn(item, index)">...</div>
          </td>
          <td>{{ item.specification }}</td>
          <td>
            <input class="count_ipt" v-model="item.count" @blur="countBlur()"  type="text" name="" id="">
          </td>
          <td>
            <input class="remove_btn" type="button" value="删除" @click="removeBtn(index)">
          </td>
        </tr>
        <tr class="thead_container">
          <td class="text_r">合计:</td>
          <td class="text_l">(单位)</td>
          <td class="text_l">{{ totalCount }}</td>
          <td style="display: flex; justify-content: center;"><input v-if="isShowAddBtn && this.tableList.length > 3" class="add_btn" type="button" value="增加" @click="addBtn()"></td>
        </tr>
      </table>
    </div>


    <!-- 模态框（隐藏） -->
    <div class="modal" :class="isOpenModal ? 'open_modal' : 'close_modal'">
      <!-- 模态框内容 -->
      <div class="modal-content">
        <div class="code_ipt">
          <input type="text" name="" v-model="code" placeholder="请输入条形码" id="">
        </div>
        <div class="modal_form">
          <table class="my_modal_table" align="center">
            <tr class="thead_container">
              <td><input type="checkbox" @change="toggleAllSelection" :checked="allSelected"></td>
              <td>条形码</td>
              <td>商品名称</td>
              <td>分类</td>
              <td>规格</td>
            </tr>
            <tr class="modal_table" v-for="(item, index) in tableData" :key="index">
              <td>
                <input type="checkbox" :value="item" v-model="selectedItems">
              </td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.specification }}</td>
            </tr>
          </table>
        </div>
        <div class="form_footer">
          <input class="submit_btn" type="button" value="选中并关闭" @click="submitForm()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  name: 'App',
  components: { VueDatePicker },
  data() {
    return {
      date: null, //日期选择
      totalCount: 0,
      isShowAddBtn: false, //是否显示新增按钮
      isOpenModal: false, //是否展示模态框
      // 表格数据
      tableList: [
        { name: '', specification: '', count: 0 },
        { name: '', specification: '', count: 0 },
        { name: '', specification: '', count: 0 },
      ],
      code: '', //输入的条形码
      isEdit: false, //选中当前行是否已有数据
      active_name: '', //选中的当前行名称
      // 提交的表单信息
      tableData: [
        { name: '天一丸', code: 3032670, category: '保健品', specification: '盒', count: 1 },
        { name: '飞升丹', code: 2443238, category: '药物', specification: '颗', count: 1 },
        { name: '转灵符', code: 2845710, category: '符咒', specification: '张', count: 1 },
        { name: '九转仙灵露', code: 284245, category: '饮品', specification: '瓶', count: 1 },
        { name: '金疮药', code: 2845990, category: '膏药', specification: '贴', count: 1 }
      ],
      selectedItems: [],
      active_index: null //当前选中的行数
    }
  },
  computed: {
    // 是否全选
    allSelected: {
      get() {
        return this.selectedItems.length === this.tableData.length;
      },
      set(value) {
        this.selectedItems = value ? this.tableData.slice() : [];
      }
    }
  },
  methods: {
    countBlur() {
      this.totalCount = this.tableList.reduce((t, item) => {
        return (t - 0) + (item.count - 0)
      }, 0)
    },
    // 修改
    editBtn(item, index) {
      console.log(item);
      this.active_name = item.name;
      if(item.name) {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
      this.isOpenModal = true;
      this.active_index = index;
      
      this.selectedItems = [];
      this.allSelected = false;
    },
    // 新增
    addBtn() {
      this.tableList.push({
        name: '',
        specification: '',
        count: 0
      })
    },
    // 删除
    removeBtn(index) {
      let confirmed = window.confirm('确认删除吗?');
      if (!confirmed) return;
      if (this.tableList.length === 1) {
        window.confirm('请至少保留一条数据');
      } else {
        this.tableList.splice(index, 1)
        this.countBlur()
      }
    },
    toggleAllSelection(event) {
      this.allSelected = event.target.checked;
      console.log(this.allSelected, this.selectedItems);
    },
    // 提交表单信息
    submitForm() {
      if (this.isEdit) {
        //如果选中的当前行已有数据 直接覆盖
        this.tableList.splice(this.active_index , 1, ...this.selectedItems);
      } else {
        if (this.selectedItems.length > 3) {
          const newArr = this.tableList.filter(item => item.name !== '')
          const result = newArr.concat(this.selectedItems)
          this.tableList = result
        } else {
          this.tableList.splice(this.active_index , this.selectedItems.length, ...this.selectedItems);
        }
        
      }
      this.countBlur()
      this.resetForm()
    },
    resetForm() {
      this.isOpenModal = false;
      this.isShowAddBtn = true;
      this.isEdit = false;
      this.selectedItems = [];
      this.active_index = null;
      this.active_name = '';
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.title {
  width: 54%;
  padding: 0 3%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

#datePicker {
  display: flex;
  align-items: center;
}

.title_label {
  margin-right: 10px;
}

.my_table {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  width: 60%;
}

.my_table > tr,.my_modal_table >tr {
  border-bottom: 1px solid #dbdbdb;
}

tr > td {
  border-right: 1px solid #dbdbdb;
  text-align: left;
  padding-left: 10px;
}

tr>td:last-child {
  text-align: center;
}

.dropdown {
  color: #fff;
}

.dropdown:hover {
  color: #999;
}

.dp_td {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.thead_container {
  height: 40px;
  font-weight: bold;
  background-color: #f1eeee;
}

.thead_container>td {
  text-align: center;
}

.name_ipt,
.count_ipt {
  height: 24px;
  width: 80%;
}

.count_ipt {
  width: 50%;
}

td {
  padding: 0 5px;
}

.text_r {
  text-align: right !important;
  padding-right: 10px;
}

.text_l {
  text-align: left !important;
  padding-left: 10px;
}

/* 按钮样式 */
.add_btn,
.remove_btn {
  cursor: pointer;
  color: white;
  border-radius: 4px;
  padding: 4px 18px;
  border: none;
}

.add_btn {
  display: flex;
  background-color: rgb(20, 131, 234);
  border-color: rgb(20, 131, 234);
  margin: 20px 0;
}

.remove_btn {
  background-color: red;
  border-color: red;
}

.submit_btn {
  border-radius: 8px;
  border: none;
  display: flex;
  color: white;
  background-color: rgb(2, 155, 99);
  border-color: rgb(2, 155, 99);
  padding: 6px 18px;
  cursor: pointer;
}

.code-t {
    display: flex;
    justify-content: center;
    align-items: center;
  }

/* 模态框样式 */
.open_modal {
  display: block;
}

/* 默认隐藏 */
.close_modal {
  display: none;
}

.modal {
  position: fixed;
  /* 固定位置 */
  z-index: 1;
  /* 位于顶层 */
  left: 0;
  top: 0;
  width: 100%;
  /* 全屏宽度 */
  height: 100%;
  /* 全屏高度 */
  overflow: auto;
  /* 超出内容可滚动 */
  background-color: rgb(0, 0, 0);
  /* 背景颜色带些透明度 */
  background-color: rgba(0, 0, 0, 0.4);
  /* 透明度 */
}

/* 请输入条形码 */
.code_ipt {
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.code_ipt>input {
  height: 28px;
}

/* 模态框内容 */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  /* 垂直居中 */
  padding: 1% 2% 5% 2%;
  border: 1px solid #888;
  width: 45%;
  border-radius: 2px;
}

.my_modal_table {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 15px;
}

.modal_table {
  height: 40px;
}

.modal_table > td {
  text-align: center;
}

/* 选中并关闭按钮 */
.form_footer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .my_table {
    width: 100%;
  }
  table {
    font-size: 10px;
  }

  #datePicker {
    width: 50%;
  }

  .title {
    width: 100%;
    font-size: 10px;
    padding: 0;
  }

  .date-picker {
     width: 100%;
  }

  .title_label {
    width: 48%;
  }



  .modal-content {
    width: 80%;
  }

  .thead_container {
    height: 20px;
  }

  .add_btn,
  .remove_btn {
    cursor: pointer;
    color: white;
    border-radius: 4px;
    padding: 1px 3px;
    font-size: 10px;
  }
}
</style>
